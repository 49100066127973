<template>
    <div class="video-player">
        <VideoPlaceholder
            v-if="!showVideo"
            :placeholder-image="placeholderImage"
            @click="startVideo"
        />

        <VideoPlayerContent v-else :video="video" />
    </div>
</template>


<script>
import VideoPlaceholder from './VideoPlaceholder.vue';
import VideoPlayerContent from './VideoPlayerContent.vue';

export default {
    components: {
        VideoPlaceholder,
        VideoPlayerContent,
    },

    props: {
        video: {
            type: String,
            required: true,
        },

        placeholderImage: {
            type: String,
            required: true,
        },
    },

    emits: ['clicked-play'],

    data() {
        return {
            showVideo: false,
        };
    },

    methods: {
        startVideo() {
            this.showVideo = true;
            this.$emit('clicked-play');
        },
    },
};
</script>

<style lang="scss" scoped>
    .video-player {
        width: 100%;
        height: 100%;
    }
</style>
