/* eslint-disable max-classes-per-file */
import { inject, unref } from 'vue';
import jwtDecode from 'jwt-decode';
import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    ApolloLink,
    from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export const APOLLO_CLIENT_KEY = 'apollo-client';

export class JwtMissingError extends Error {
    constructor(message) {
        super(message);
        this.name = 'JwtMissingError';
    }
}

export class JwtExpiredError extends Error {
    constructor(message) {
        super(message);
        this.name = 'JwtExpiredError';
    }
}

const isJwtExpired = (jwt) => {
    const { exp } = jwtDecode(jwt);

    if (exp) {
        return exp * 1000 < Date.now();
    }

    return true;
};

export function createApolloClient(options) {
    const { uri, apolloClientOptions } = options;

    const httpLink = new HttpLink({ uri });

    const authMiddleware = new ApolloLink((operation, forward) => {
        const jwt = unref(options.jwt);
        const appId = unref(options.appId);
        const sessionAffinity = unref(options.sessionAffinity);

        if (jwt == null) {
            throw new JwtMissingError('JWT is not present in the auth session.');
        }

        if (isJwtExpired(jwt)) {
            throw new JwtExpiredError('JWT is expired.');
        }

        const headers = {
            Authorization: `Bearer ${jwt}`,
            'X-IS-App-Name': appId,
        };

        if (sessionAffinity != null) {
            headers['X-IS-Affinity'] = sessionAffinity.value;
        }

        operation.setContext({ headers });

        return forward(operation);
    });

    const logoutLink = onError(({ networkError }) => {
        if (networkError instanceof JwtExpiredError) {
            window.location = '/logout?checkCas=true';
        }

        if (networkError instanceof JwtMissingError) {
            window.location = '/logout';
        }

        if (networkError && networkError.statusCode === 401) {
            window.location = '/logout?checkCas=true';
        }
    });

    const client = new ApolloClient({
        uri,
        link: from([
            authMiddleware,
            logoutLink,
            httpLink,
        ]),
        cache: new InMemoryCache({
            addTypename: false,
        }),
        ...apolloClientOptions,
    });

    return {
        client,
        install(app) {
            app.provide(APOLLO_CLIENT_KEY, client);
            app.config.globalProperties.$graphql = client;
        },
    };
}

export function useApolloClient() {
    return inject(APOLLO_CLIENT_KEY);
}
