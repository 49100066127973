import { ACTIVE_UI, URL_REPLACE_STRING, createLinks } from './share.links.constants';

const { WEB, CLASSIC, ULTIMATE } = ACTIVE_UI;
const { RESOURCE_ID } = URL_REPLACE_STRING;

// These values are used by the backend service
export const RESOURCE_TYPES = {
    APPOINTMENT_BOOKING_URL: 'AppointmentBookingUrl',
    APPOINTMENT_TYPE: 'AppointmentType',
    BUNDLE: 'Bundle',
    CAMPAIGN: 'Campaign',
    CHECKOUT_FORM: 'CheckoutForm',
    CHECKOUT_FORM_URL: 'CheckoutFormUrl',
    CUSTOM_FIELD: 'CustomField', // this doesn't exist in the backend service
    CONTACT_CUSTOM_FIELD: 'ContactCustomField',
    COMPANY_CUSTOM_FIELD: 'CompanyCustomField',
    EASY_AUTOMATION: 'EasyAutomation',
    EMAIL_BROADCAST: 'EmailBroadcast',
    EMAIL_TEMPLATE: 'EmailTemplate',
    FORM: 'Form',
    FORM_URL: 'FormUrl',
    HOSTED_FILE_URL: 'HostedFileUrl',
    LANDING_PAGE: 'LandingPage',
    LANDING_PAGE_URL: 'LandingPageUrl',
    LEAD_SOURCE: 'LeadSource',
    LEGACY_MERGE_FIELD: 'LegacyMergeField',
    MERGE_FIELD: 'MergeField',
    SALES_PIPELINE: 'SalesPipeline',
    SALES_PIPELINE_STAGE: 'SalesPipelineStage',
    SAVED_SEARCH: 'SavedSearch',
    TAG: 'Tag',
    PLAY_TEMPLATE: 'PlayTemplate',
    TEMPLATE: 'Template',
    TEXT_TEMPLATE: 'TextTemplate',
    URL: 'Url',
};

export const RESOURCE_SUBTYPES = {
    CONTACT: 'Contact',
    INTERNAL_FORM: 'Internal',
    PUBLIC_FORM: 'Public',
    EMAIL_SMS_TEMPLATE: 'Email',
    SMS_BROADCAST_TEMPLATE: 'SmsBroadcast',
    SMS_AUTOMATION_TEMPLATE: 'SmsAutomation',
    SMS_CUSTOM_TEMPLATE: 'SmsCustom',
    SAVED_SEARCH_CONTACT: 'Contact',
    SAVED_SEARCH_COMPANY: 'Company',
    SAVED_SEARCH_ORDER: 'Order',
    SAVED_SEARCH_VISITOR: 'Visitor',
    SAVED_SEARCH_SUBSCRIPTION: 'Subscription',
    SAVED_SEARCH_REFERRAL_PARTNER: 'ReferralPartner',
};

const companyCustomField = {
    id: RESOURCE_TYPES.COMPANY_CUSTOM_FIELD,
    icon: 'company',
    label: 'ContentSharingLib.share.item.type.companyField',
    listLabel: 'ContentSharingLib.share.item.type.companyField.list',
    links: {
        [WEB]: createLinks(WEB, `/settings/custom-fields/company/${RESOURCE_ID}`, WEB, '/settings/custom-fields/company'),
        [CLASSIC]: null,
        [ULTIMATE]: null,
    },
};
const contactCustomField = {
    id: RESOURCE_TYPES.CONTACT_CUSTOM_FIELD,
    resourceType: RESOURCE_TYPES.CONTACT_CUSTOM_FIELD,
    icon: 'contact-user',
    label: 'ContentSharingLib.share.item.type.contactField',
    listLabel: 'ContentSharingLib.share.item.type.contactField.list',
    links: {
        [WEB]: createLinks(WEB, `/settings/custom-fields/contact/${RESOURCE_ID}`, WEB, '/settings/custom-fields'),
        [CLASSIC]: createLinks(CLASSIC, '/Admin/custom/manageCustomFields.jsp?table=Contact', CLASSIC, '/Admin/custom/manageCustomFields.jsp?table=Contact'),
        [ULTIMATE]: createLinks(ULTIMATE, '/core/Admin/custom/manageCustomFields.jsp?table=Contact', ULTIMATE, '/core/Admin/custom/manageCustomFields.jsp?table=Contact'),
    },
};

export const ITEM_TYPES = {
    [RESOURCE_TYPES.BUNDLE]: {
        id: RESOURCE_TYPES.BUNDLE,
        icon: 'package',
        label: 'ContentSharingLib.share.item.type.bundle',
        isOnShareableTypesList: false,
        isOnSharedTypesList: true,
    },
    [RESOURCE_TYPES.LEAD_SOURCE]: {
        id: RESOURCE_TYPES.LEAD_SOURCE,
        icon: 'form',
        label: 'ContentSharingLib.share.item.type.leadSource',
    },
    [RESOURCE_TYPES.FORM_URL]: {
        id: RESOURCE_TYPES.FORM_URL,
        icon: 'form',
        label: 'ContentSharingLib.share.item.type.formUrl',
    },
    [RESOURCE_TYPES.HOSTED_FILE_URL]: {
        id: RESOURCE_TYPES.HOSTED_FILE_URL,
        icon: 'file',
        label: 'ContentSharingLib.share.item.type.hostedFileUrl',
    },
    [RESOURCE_TYPES.CONTACT_CUSTOM_FIELD]: contactCustomField,
    [RESOURCE_TYPES.COMPANY_CUSTOM_FIELD]: companyCustomField,
    [RESOURCE_TYPES.CUSTOM_FIELD]: {
        id: RESOURCE_TYPES.CUSTOM_FIELD,
        icon: 'contact-user',
        label: 'ContentSharingLib.share.item.type.customField',
        subtypes: {
            [RESOURCE_TYPES.CONTACT_CUSTOM_FIELD]: {
                ...contactCustomField,
                resourceType: RESOURCE_TYPES.CONTACT_CUSTOM_FIELD,
            },
            [RESOURCE_TYPES.COMPANY_CUSTOM_FIELD]: {
                ...companyCustomField,
                resourceType: RESOURCE_TYPES.COMPANY_CUSTOM_FIELD,
            },
        },
    },
    [RESOURCE_TYPES.LEGACY_MERGE_FIELD]: {
        id: RESOURCE_TYPES.LEGACY_MERGE_FIELD,
        icon: 'merge-field',
        label: 'ContentSharingLib.share.item.type.legacyMergeField',
    },
    [RESOURCE_TYPES.MERGE_FIELD]: {
        id: RESOURCE_TYPES.MERGE_FIELD,
        icon: 'merge-field',
        label: 'ContentSharingLib.share.item.type.mergeField',
    },
    [RESOURCE_TYPES.URL]: {
        id: RESOURCE_TYPES.URL,
        icon: 'click',
        label: 'ContentSharingLib.share.item.type.url',
    },
    [RESOURCE_TYPES.CAMPAIGN]: {
        id: RESOURCE_TYPES.CAMPAIGN,
        icon: 'campaign',
        label: 'ContentSharingLib.share.item.type.advancedAutomation',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        links: {
            [WEB]: createLinks(CLASSIC, `/app/funnel/funnelEditor?funnelId=${RESOURCE_ID}&view=default`, WEB, '/automations/advanced'),
            [CLASSIC]: createLinks(CLASSIC, `/app/funnel/funnelEditor?funnelId=${RESOURCE_ID}&view=default`, CLASSIC, '/Reports/searchTemplate.jsp'),
            [ULTIMATE]: createLinks(CLASSIC, `/app/funnel/funnelEditor?funnelId=${RESOURCE_ID}&view=default`, CLASSIC, '/Reports/searchTemplate.jsp'),
        },
    },
    [RESOURCE_TYPES.FORM]: {
        id: RESOURCE_TYPES.FORM,
        icon: 'form',
        label: 'ContentSharingLib.share.item.type.form',
        listLabel: 'ContentSharingLib.share.item.type.form',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        subtypes: {
            [RESOURCE_SUBTYPES.INTERNAL_FORM]: {
                id: RESOURCE_SUBTYPES.INTERNAL_FORM,
                icon: 'form',
                label: 'ContentSharingLib.share.item.type.internalForm',
                listLabel: 'ContentSharingLib.share.item.type.internalForm.list',
                links: {
                    [WEB]: createLinks(WEB, `/forms/internal/${RESOURCE_ID}/edit/build`, WEB, '/forms/internal'),
                    [CLASSIC]: null,
                    [ULTIMATE]: null,
                },
            },
            [RESOURCE_SUBTYPES.PUBLIC_FORM]: {
                id: RESOURCE_SUBTYPES.PUBLIC_FORM,
                icon: 'form',
                label: 'ContentSharingLib.share.item.type.publicForm',
                listLabel: 'ContentSharingLib.share.item.type.publicForm.list',
                links: {
                    [WEB]: createLinks(WEB, `/forms/public/${RESOURCE_ID}/edit/build`, WEB, '/forms/public'),
                    [CLASSIC]: null,
                    [ULTIMATE]: null,
                },
            },
        },
    },
    [RESOURCE_TYPES.APPOINTMENT_BOOKING_URL]: {
        id: RESOURCE_TYPES.APPOINTMENT_BOOKING_URL,
        icon: 'calendar',
        label: 'ContentSharingLib.share.item.type.appointmentBookingUrl',
    },
    [RESOURCE_TYPES.APPOINTMENT_TYPE]: {
        id: RESOURCE_TYPES.APPOINTMENT_TYPE,
        icon: 'calendar',
        label: 'ContentSharingLib.share.item.type.appointmentType',
        links: {
            [WEB]: createLinks(WEB, '/appointments', WEB, '/appointments'),
            [CLASSIC]: null,
            [ULTIMATE]: null,
        },
    },
    [RESOURCE_TYPES.TAG]: {
        id: RESOURCE_TYPES.TAG,
        icon: 'tag',
        label: 'ContentSharingLib.share.item.type.tag',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        links: {
            [WEB]: createLinks(WEB, `/settings/tags/${RESOURCE_ID}`, WEB, '/settings/tags'),
            [CLASSIC]: createLinks(CLASSIC, `/ContactGroup/manageContactGroup.jsp?view=edit&ID=${RESOURCE_ID}`, CLASSIC, '/Reports/searchTemplate.jsp?reportClass=com.infusion.util.report.reports.setup.ContactGroupSearch'),
            [ULTIMATE]: createLinks(ULTIMATE, `/core/ContactGroup/manageContactGroup.jsp?view=edit&ID=${RESOURCE_ID}`, ULTIMATE, '/core/Reports/searchTemplate.jsp?reportClass=com.infusion.util.report.reports.setup.ContactGroupSearch'),
        },
    },
    [RESOURCE_TYPES.EMAIL_TEMPLATE]: {
        id: RESOURCE_TYPES.EMAIL_TEMPLATE,
        icon: 'email',
        label: 'ContentSharingLib.share.item.type.email',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        links: {
            [WEB]: null,
            [CLASSIC]: null,
            [ULTIMATE]: null,
        },
    },
    [RESOURCE_TYPES.TEMPLATE]: {
        id: RESOURCE_TYPES.TEMPLATE,
        icon: 'form',
        label: 'ContentSharingLib.share.item.type.template',
    },
    [RESOURCE_TYPES.LANDING_PAGE]: {
        id: RESOURCE_TYPES.LANDING_PAGE,
        icon: 'templates',
        label: 'ContentSharingLib.share.item.type.landingPage',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        links: {
            [WEB]: createLinks(WEB, `/marketing-sites/${RESOURCE_ID}/edit`, WEB, '/marketing-sites'),
            [CLASSIC]: createLinks(CLASSIC, `/app/marketingsite/editor/${RESOURCE_ID}`, CLASSIC, '/app/nav/link?navSystem=nav.marketing&navModule=marketingsite'),
            [ULTIMATE]: createLinks(ULTIMATE, `/core/app/marketingsite/editor/${RESOURCE_ID}`, ULTIMATE, '/marketing-sites'),
        },
    },
    [RESOURCE_TYPES.LANDING_PAGE_URL]: {
        id: RESOURCE_TYPES.LANDING_PAGE_URL,
        icon: 'templates',
        label: 'ContentSharingLib.share.item.type.landingPageUrl',
    },
    [RESOURCE_TYPES.CHECKOUT_FORM]: {
        id: RESOURCE_TYPES.CHECKOUT_FORM,
        icon: 'shopping-cart',
        label: 'ContentSharingLib.share.item.type.checkoutForm',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        links: {
            [WEB]: createLinks(WEB, `/sales/checkout-form/${RESOURCE_ID}`, WEB, '/sales/checkout'),
            [CLASSIC]: null,
            [ULTIMATE]: null,
        },
    },
    [RESOURCE_TYPES.CHECKOUT_FORM_URL]: {
        id: RESOURCE_TYPES.CHECKOUT_FORM_URL,
        icon: 'shopping-cart',
        label: 'ContentSharingLib.share.item.type.checkoutFormUrl',
    },
    [RESOURCE_TYPES.SALES_PIPELINE]: {
        id: RESOURCE_TYPES.SALES_PIPELINE,
        icon: 'pipeline',
        label: 'ContentSharingLib.share.item.type.salesPipeline',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        links: {
            [WEB]: createLinks(WEB, `/pipeline/${RESOURCE_ID}`, WEB, '/pipeline'),
            [CLASSIC]: null,
            [ULTIMATE]: null,
        },
    },
    [RESOURCE_TYPES.SALES_PIPELINE_STAGE]: {
        id: RESOURCE_TYPES.SALES_PIPELINE_STAGE,
        icon: 'pipeline',
        label: 'ContentSharingLib.share.item.type.pipelineStage',
    },
    [RESOURCE_TYPES.PLAY_TEMPLATE]: {
        id: RESOURCE_TYPES.PLAY_TEMPLATE,
        icon: 'playbook',
        label: 'ContentSharingLib.share.item.type.play',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        links: {
            [WEB]: createLinks(WEB, '/getting-started', WEB, '/getting-started'),
            [CLASSIC]: null,
            [ULTIMATE]: createLinks(ULTIMATE, '/getting-started', ULTIMATE, '/getting-started'),
        },
    },
    [RESOURCE_TYPES.EASY_AUTOMATION]: {
        id: RESOURCE_TYPES.EASY_AUTOMATION,
        icon: 'zap',
        label: 'ContentSharingLib.share.item.type.easyAutomation',
        links: {
            [WEB]: createLinks(WEB, `/automations/build/${RESOURCE_ID}`, WEB, '/automations/easy'),
            [CLASSIC]: null,
            [ULTIMATE]: null,
        },
    },
    [RESOURCE_TYPES.EMAIL_BROADCAST]: {
        id: RESOURCE_TYPES.EMAIL_BROADCAST,
        icon: 'megaphone',
        label: 'ContentSharingLib.share.item.type.emailBroadcast',
        isOnShareableTypesList: true,
        isOnSharedTypesList: true,
        links: {
            [WEB]: createLinks(WEB, `/broadcasts/email/unified/${RESOURCE_ID}`, WEB, '/broadcasts/email'),
            [CLASSIC]: createLinks(CLASSIC, '/app/nav/link?navSystem=nav.marketing&navModule=broadcast', CLASSIC, '/app/nav/link?navSystem=nav.marketing&navModule=broadcast'),
            [ULTIMATE]: createLinks(ULTIMATE, '/broadcasts/email', ULTIMATE, '/broadcasts/email'),
        },
    },
    [RESOURCE_TYPES.SAVED_SEARCH]: {
        id: RESOURCE_TYPES.SAVED_SEARCH,
        icon: 'contacts-users',
        label: 'ContentSharingLib.share.item.type.savedSearch',
        listLabel: 'ContentSharingLib.share.item.type.savedSearch.list',
        subtypes: {
            [RESOURCE_SUBTYPES.SAVED_SEARCH_CONTACT]: {
                id: RESOURCE_SUBTYPES.SAVED_SEARCH_CONTACT,
                icon: 'contacts-users',
                label: 'ContentSharingLib.share.item.type.savedSearch.contact',
                listLabel: 'ContentSharingLib.share.item.type.savedSearch.contact.list',
                links: {
                    [WEB]: createLinks(WEB, `/contacts/list/${RESOURCE_ID}`, WEB, '/contacts/list/view'),
                    [CLASSIC]: createLinks(CLASSIC, '/Reports/searchTemplate.jsp?reportClass=AdvContactSearch', CLASSIC, '/Reports/searchTemplate.jsp?reportClass=AdvContactSearch'),
                    [ULTIMATE]: createLinks(ULTIMATE, '/core/Reports/searchTemplate.jsp?reportClass=AdvContactSearch', ULTIMATE, '/core/Reports/searchTemplate.jsp?reportClass=AdvContactSearch'),
                },
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_COMPANY]: {
                id: RESOURCE_SUBTYPES.SAVED_SEARCH_COMPANY,
                icon: 'company',
                label: 'ContentSharingLib.share.item.type.savedSearch.company',
                listLabel: 'ContentSharingLib.share.item.type.savedSearch.company.list',
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_ORDER]: {
                id: RESOURCE_SUBTYPES.SAVED_SEARCH_ORDER,
                icon: 'shopping-cart',
                label: 'ContentSharingLib.share.item.type.savedSearch.order',
                listLabel: 'ContentSharingLib.share.item.type.savedSearch.order.list',
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_VISITOR]: {
                id: RESOURCE_SUBTYPES.SAVED_SEARCH_VISITOR,
                icon: 'contacts-users',
                label: 'ContentSharingLib.share.item.type.savedSearch.visitor',
                listLabel: 'ContentSharingLib.share.item.type.savedSearch.visitor.list',
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_SUBSCRIPTION]: {
                id: RESOURCE_SUBTYPES.SAVED_SEARCH_SUBSCRIPTION,
                icon: 'contacts-users',
                label: 'ContentSharingLib.share.item.type.savedSearch.subscription',
                listLabel: 'ContentSharingLib.share.item.type.savedSearch.subscription.list',
            },
            [RESOURCE_SUBTYPES.SAVED_SEARCH_REFERRAL_PARTNER]: {
                id: RESOURCE_SUBTYPES.SAVED_SEARCH_REFERRAL_PARTNER,
                icon: 'contacts-users',
                label: 'ContentSharingLib.share.item.type.savedSearch.referralPartner',
                listLabel: 'ContentSharingLib.share.item.type.savedSearch.referralPartner.list',
            },
        },
    },
    [RESOURCE_TYPES.TEXT_TEMPLATE]: {
        id: RESOURCE_TYPES.TEXT_TEMPLATE,
        icon: 'message',
        label: 'ContentSharingLib.share.item.type.textTemplate',
        listLabel: 'ContentSharingLib.share.item.type.textTemplate.list',
        subtypes: {
            [RESOURCE_SUBTYPES.EMAIL_SMS_TEMPLATE]: {
                id: RESOURCE_SUBTYPES.EMAIL_SMS_TEMPLATE,
                icon: 'message',
                label: 'ContentSharingLib.share.item.type.textTemplateEmail',
                listLabel: 'ContentSharingLib.share.item.type.textTemplateEmail.list',
            },
            [RESOURCE_SUBTYPES.SMS_BROADCAST_TEMPLATE]: {
                id: RESOURCE_SUBTYPES.SMS_BROADCAST_TEMPLATE,
                icon: 'message',
                label: 'ContentSharingLib.share.item.type.textTemplateSmsBroadcast',
                listLabel: 'ContentSharingLib.share.item.type.textTemplateSmsBroadcast.list',
            },
            [RESOURCE_SUBTYPES.SMS_AUTOMATION_TEMPLATE]: {
                id: RESOURCE_SUBTYPES.SMS_AUTOMATION_TEMPLATE,
                icon: 'message',
                label: 'ContentSharingLib.share.item.type.textTemplateSmsAutomation',
                listLabel: 'ContentSharingLib.share.item.type.textTemplateSmsAutomation.list',
            },
            [RESOURCE_SUBTYPES.SMS_CUSTOM_TEMPLATE]: {
                id: RESOURCE_SUBTYPES.SMS_CUSTOM_TEMPLATE,
                icon: 'message',
                label: 'ContentSharingLib.share.item.type.textTemplateSmsCustom',
                listLabel: 'ContentSharingLib.share.item.type.textTemplateSmsCustom.list',
            },
        },
    },
};

export const PLURAL_LABEL_COUNT = 2;
export const MAX_INSTALL_TIMEOUT = 20000;

// These values are used by the backend service
export const REQUEST_STATUSES = {
    VALIDATING: 'Validating',
    QUEUED: 'Queued',
    IN_PROGRESS: 'InProgress',
    EXPORTING: 'Exporting',
    INSTALLING: 'Installing',
    BUILDING_DEPENDENCIES: 'BuildingDependencies',
    LOADING_DEPENDENCIES: 'LoadingDependencies',
    NEEDS_RETRIED: 'NeedsRetried',
    COMPLETED: 'Completed',
    FAILED: 'Failed',
};

// These values are used by the backend service
export const FAILURE_REASON = {
    PROVIDER_FAILURE: 'ProviderFailure',
    CIRCULAR_DEPENDENCY: 'CircularDependency',
    DEPENDENCY_NOT_FOUND: 'DependencyNotFound',
    ILLEGAL_DEPENDENCY: 'IllegalDependency',
    SHARED_RESOURCE_NOT_FOUND: 'SharedResourceNotFound',
    UNKNOWN_FAILURE: 'UnknownFailure',
    USER_IS_NOT_ADMIN_ON_TARGET_TENANT: 'UserIsNotAdminOnTargetTenant',
    APP_MISSING_REQUIRED_FEATURES: 'MissingRequiredFeatures',
    TRACKED_RESOURCE_NOT_FOUND: 'TrackedResourceNotFound',
    RESHARING_FORBIDDEN: 'ReSharingForbidden',
    SOURCE_TENANT_MATCHES_DESTINATION: 'SourceTenantMatchesDestination',
    PRIVATE_MARKETPLACE_CAMPAIGN: 'PrivateMarketplaceCampaign',
    USER_NOT_LICENSED: 'UserIsNotLicensed',
    LINK_UNPUBLISHED: 'LinkUnpublished',
    LICENSE_LIMIT_REACHED: 'LicenseLimitExhausted',
    REFERENCE_NOT_FOUND: 'referencedObjectNotFound',
    BUNDLE_EMPTY: 'BundleEmpty',
    SOURCE_RESOURCE_NOT_FOUND: 'SourceResourceNotFound',
    LINK_NOT_FOUND: 'LinkNotFound',
    BULK_VALIDATION_FAILURE: 'BulkValidationFailure',
    COUNT_EXCEEDS_LIMIT: 'CountExceedsLimit',
    CONFLICTS_WITH_EXISTING: 'ResourceConflictsWithExisting',
    CONFLICTING_TRACKED_RESOURCES: 'ConflictingTrackedResources',
    TOO_MANY_REQUESTS: 'TooManyRequests',
    LICENCE_INVALID: 'LicenseInvalidInThisContext',
};

// These values are used by the backend service
export const LINK_TYPE = {
    PUBLISHED: 'Published',
    UNPUBLISHED: 'Unpublished',
    UNKNOWN: 'unknown',
};

export const LICENSE_TYPE = {
    OPEN: 'Open',
    BUY_NOW: 'BuyNow',
    LIMITED_USE: 'LimitedUse',
};
