import axios from 'axios';
import { useAuthStore } from '@/stores/auth';

const setupAxiosRequestInterceptor = () => {
    const authStore = useAuthStore();

    axios.interceptors.request.use((config) => {
        const { jwt } = authStore;
        const hasJwt = Boolean(jwt);
        const alreadyHasAuth = Boolean(config.headers.Authorization);

        if (hasJwt && !alreadyHasAuth) {
            config.headers.Authorization = `Bearer ${jwt}`;
        }

        return config;
    });
};

const setupAxiosResponseInterceptor = (router) => {
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                router.push({ name: 'logout', query: { renew: true } });
            }

            if (error.response && error.response.status === 451) {
                const authStore = useAuthStore();

                authStore.resetTermsAccepted();
            }

            return Promise.reject(error);
        },
    );
};

export const setupInterceptors = (router) => {
    setupAxiosRequestInterceptor();

    setupAxiosResponseInterceptor(router);
};
