import { defineStore } from 'pinia';
import { IDLE, SUCCESS, ERROR } from '@/constants/loadingStatuses.constants';
import jwtDecode from 'jwt-decode';
import { useTutorialStore } from '@/stores/tutorials';
import { useGlobalStore } from '@/stores/global';
import { useShareStore } from '@/share/store';
import { useAccountsStore } from '@/accounts/store';
import { loadTermsAccepted, acceptCurrentTerms } from './api';

const isJwtExpired = (exp) => {
    if (exp) {
        return exp * 1000 < Date.now();
    }

    return true;
};

export const useAuthStore = defineStore('auth', {
    state: () => ({
        status: IDLE,
        jwt: null,
        user: {},
        termsAccepted: false,
    }),

    getters: {
        isLoggedIn: ({ jwt, user }) => {
            return Boolean(jwt && user?.id);
        },
    },

    actions: {
        startSession({ jwt, user }) {
            this.status = SUCCESS;
            this.jwt = jwt;
            this.user = user;
        },

        endSession() {
            this.reset();
            useTutorialStore().reset();
        },

        failSession() {
            this.reset();
            this.status = ERROR;
            useTutorialStore().reset();
        },

        async login(jwt = null) {
            if (jwt) {
                try {
                    const jwtDecoded = jwtDecode(jwt);

                    const {
                        email, firstName, id, lastName, displayName, exp,
                    } = jwtDecoded;

                    if (isJwtExpired(exp)) {
                        this.endSession();
                    } else {
                        const globalStore = useGlobalStore();
                        const user = {
                            id,
                            email,
                            firstName,
                            lastName,
                            displayName,
                            exp,
                        };

                        this.startSession({ jwt, user });
                        globalStore.loadBoot({ reload: false });
                    }
                } catch (e) {
                    this.failSession();
                }
            } else {
                this.endSession();
            }
        },

        async loadTerms(appId) {
            try {
                const termsAccepted = await loadTermsAccepted(appId);

                this.termsAccepted = termsAccepted;
            } catch (e) {
                this.termsAccepted = false;
            }
        },

        async acceptTerms(appId) {
            await acceptCurrentTerms(appId);

            this.termsAccepted = true;
        },

        resetTermsAccepted() {
            this.termsAccepted = false;
        },

        verifyLogin() {
            if (isJwtExpired(this.user?.exp)) {
                this.endSession();
            }
        },

        logout() {
            this.endSession();
            useAccountsStore().updateAppId('');
            useShareStore().setSourceSandboxId('');
        },

        reset() {
            this.$reset();
        },
    },

    persist: true,
});
