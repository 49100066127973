<template>
    <div class="ftu-page">
        <DsStack
            v-if="showFtu"
            space="spacing-400"
            class="ftu-content"
            data-qa="ftu-info"
        >
            <DsInline align-x="center">
                <DsIllustration name="rocket" size="mega" />
            </DsInline>

            <DsText class="title" variant="display-alt">
                {{ $t('ftu.title') }}
            </DsText>

            <DsText class="description" variant="body">
                {{ $t('ftu.description') }}
            </DsText>
        </DsStack>

        <DsBox v-if="showTerms" class="terms-container">
            <DsCheckbox
                v-model="termsChecked"
                data-qa="accept-terms"
            >
                <i18n-t keypath="ftu.acceptTerms" scope="global">
                    <template #link1>
                        <DsLink :href="$options.termsLink" target="_blank">
                            {{ $t('ftu.acceptTerms.termsLink') }}
                        </DsLink>
                    </template>

                    <template #link2>
                        <DsLink :href="$options.privacyPolicyLink" target="_blank">
                            {{ $t('ftu.acceptTerms.privacyPolicyLink') }}
                        </DsLink>
                    </template>
                </i18n-t>
            </DsCheckbox>
        </DsBox>

        <DsBox padding-bottom="spacing-200" class="ftu-content">
            <DsInline align-x="center" class="button-container">
                <DsButton
                    data-qa="dismiss-ftu"
                    trailing-icon="chevron-right"
                    :loading="tutorialStatus === LOADING || consentStatus === LOADING"
                    :disabled="buttonDisabled"
                    @click="accept"
                >
                    {{ $t('button.getStarted') }}
                </DsButton>
            </DsInline>
        </DsBox>

        <VideoPlayer
            v-if="showFtu"
            data-qa="ftu-video"
            :video="$options.video"
            :placeholder-image="$options.videoPlaceholder"
            class="video-player"
        />
    </div>
</template>

<script>
import { computed } from 'vue';
import { useAccountsStore } from '@/accounts/store';
import { useAuthStore } from '@/stores/auth';
import { useTutorialStore } from '@/stores/tutorials';
import videoPlaceholder from '@/images/ftu-placeholder.png';
import { VideoPlayer } from '@keap-web/shared-ui';
import {
    IDLE, LOADING, SUCCESS, ERROR,
} from '@/constants/loadingStatuses.constants';

export default {
    components: {
        VideoPlayer,
    },

    video: 'https://keap.wistia.com/embed/medias/1dqibphy4b?autoPlay=true',
    videoPlaceholder,
    termsLink: 'https://keap.com/legal/solution-launchpad-terms',
    privacyPolicyLink: 'https://keap.com/legal/privacy-policy',

    setup() {
        const authStore = useAuthStore();
        const tutorialStore = useTutorialStore();
        const accountsStore = useAccountsStore();
        const userId = computed(() => authStore.user?.id);
        const appId = computed(() => accountsStore.appId);

        return {
            authStore,
            tutorialStore,
            isTermsAccepted: computed(() => authStore.termsAccepted),
            isFtuDismissed: computed(() => tutorialStore.items.SHARE_APP_FTU),
            userId,
            appId,
            LOADING,
        };
    },

    data() {
        return {
            termsChecked: Boolean(this.isTermsAccepted),
            tutorialStatus: IDLE,
            consentStatus: IDLE,
        };
    },

    computed: {
        buttonDisabled() {
            return this.showTerms && !this.termsChecked;
        },

        showFtu() {
            return !this.isFtuDismissed;
        },

        showTerms() {
            return !this.isTermsAccepted;
        },
    },

    methods: {
        async accept() {
            if (this.showTerms) {
                this.consentStatus = LOADING;

                try {
                    await this.authStore.acceptTerms(this.appId);
                    this.consentStatus = SUCCESS;
                } catch {
                    this.consentStatus = ERROR;
                }
            }

            if (this.showFtu) {
                this.tutorialStatus = LOADING;

                try {
                    this.tutorialStore.saveItem(this.appId, this.userId, { SHARE_APP_FTU: true });
                    this.tutorialStatus = SUCCESS;
                } catch {
                    this.tutorialStatus = ERROR;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.ftu-page {
    @include container($spacing-200, $spacing-200);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title,
.description {
    text-align: center;
}

.ftu-content {
    max-width: px-to-rem(380);
    margin-bottom: $spacing-300;
}

.terms-container {
    max-width: px-to-rem(420);
    margin-bottom: $spacing-200;
}

.video-player {
    width: px-to-rem(580);
}

// This is so the link will wrap correctly inside of the checkbox container
.terms-container:deep(.label-container),
.terms-container:deep(.link) {
    display: inline;
}

@media($small) {
    .ftu-page,
    .ftu-content,
    .terms-container,
    .button-container,
    .video-player {
        width: 100%;
        max-width: 100%;
    }
}
</style>
