<template>
    <div class="video-content">
        <div class="spinner-wrapper">
            <DsSpinner />
        </div>

        <iframe
            :src="video"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        />
    </div>
</template>

<script>
import { DsSpinner } from '@infusionsoft/design-system';

export default {
    components: {
        DsSpinner,
    },

    props: {
        video: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../styles/common';

.video-content {
    position: relative;
    padding-bottom: 52.25%; /* 16:9 */
    padding-top: px-to-rem(25px);
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.spinner-wrapper {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: .8;
    position: absolute;
    right: 0;
    top: 0;
}
</style>
