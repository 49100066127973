import axios from 'axios';
import { FAILURE_REASON } from '../../constants/share.constants';

export const createInstallRequest = async ({ tenantId, sharedResourceId }, path) => {
    const { data } = await axios.post(`${path}/api/v2/installRequests`, {
        sharedResource: { sharedResourceId },
        tenantId,
        createdById: 'current',
    });

    return data;
};

export const installRequestStatus = async ({ requestId }, path) => {
    const { data } = await axios.get(`${path}/api/v2/installRequests/${requestId}`);

    return data;
};

export const validateInstallRequest = async ({ tenantId, sharedResourceId }, path) => {
    try {
        const { data } = await axios.post(`${path}/api/v2/installRequests:validate`, {
            sharedResource: { sharedResourceId },
            tenantId,
            createdById: 'current',
        });

        return data;
    } catch {
        return { tenantId, failureReasons: [FAILURE_REASON.UNKNOWN_FAILURE] };
    }
};

export const validateLinkInstallRequest = async ({ tenantId, linkId }, path) => {
    try {
        const { data } = await axios.post(`${path}/api/v2/installRequests:validate`, {
            linkId,
            tenantId,
            createdById: 'current',
        });

        return data;
    } catch {
        return { tenantId, failureReasons: [FAILURE_REASON.UNKNOWN_FAILURE] };
    }
};

export const createLinkInstallRequest = async ({ tenantId, linkId }, path) => {
    const { data } = await axios.post(`${path}/api/v2/installRequests`, {
        linkId,
        tenantId,
        createdById: 'current',
        userAgreedToTerms: true,
    });

    return data;
};

export const loadRequestErrors = async ({ requestId, pageSize, pageToken }, path) => {
    const params = { pageSize: pageSize || 10, lastFailureOnly: true };

    if (pageToken) {
        params.pageToken = pageToken;
    } else {
        params.includeTotalSize = true;
    }

    const queryParams = new URLSearchParams(params);

    const { data } = await axios.get(`${path}/api/v2/requests/${requestId}/failures?${queryParams.toString()}`);

    return data;
};
