import { defineStore } from 'pinia';
import { URL_REPLACE_STRING } from '../../constants/share.links.constants';
import { ITEM_TYPES } from '../../constants/share.constants';

const {
    WEB_BASE, CLASSIC_BASE, ULTIMATE_BASE, TENANT_ID, RESOURCE_ID,
} = URL_REPLACE_STRING;

export const useShareUrlsStore = defineStore('shareUrls', {
    state: () => ({
        packagingServiceUrl: '',
        webUrl: '',
        ultimateUrl: '',
        classicUrl: '',
    }),

    getters: {
        getLink: ({ webUrl, ultimateUrl, classicUrl }) => (links, { tenantId, id }) => {
            if (!links) return null;

            let urlAndRoute = links.fallback;

            classicUrl = classicUrl.replace('{id}', tenantId);

            if (id) {
                urlAndRoute = links.path;
            }

            const url = urlAndRoute.url.replace(TENANT_ID, tenantId).replace(RESOURCE_ID, id);
            const routeLink = urlAndRoute.routePath ? urlAndRoute.routePath.replace(TENANT_ID, tenantId).replace(RESOURCE_ID, id) : undefined;

            return {
                url: url.replace(WEB_BASE, webUrl)
                    .replace(CLASSIC_BASE, classicUrl)
                    .replace(ULTIMATE_BASE, ultimateUrl),
                routeLink,
            };
        },

        getSharedItemsWithLinks: ({ getLink }) => (sharedItems, { activeUserInterface, tenantId }) => {
            return sharedItems.map((item) => {
                let itemType = ITEM_TYPES[item.resourceType];

                if (itemType && itemType.subtypes && item.resourceSubtype) {
                    itemType = itemType.subtypes[item.resourceSubtype];
                }

                const linksByUI = itemType?.links?.[activeUserInterface] || null;
                const urlAndRoute = getLink(linksByUI, { id: item.resourceId, tenantId });

                return {
                    ...item,
                    routeLink: urlAndRoute?.routeLink,
                    url: urlAndRoute?.url || undefined,
                };
            });
        },
    },

    actions: {
        setPackagingServiceUrl(path = '') {
            this.packagingServiceUrl = path;
        },

        setWebUrl(path = '') {
            this.webUrl = path;
        },

        setUltimateUrl(path = '') {
            this.ultimateUrl = path;
        },

        setClassicUrl(path = '') {
            this.classicUrl = path;
        },
    },
});
