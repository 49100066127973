<template>
    <RouterLink to="/">
        <DsLogo icon-only class="logo" />
    </RouterLink>
</template>

<style lang="scss" scoped>
.logo {
    --logo-width: #{$font-size-lg};
    width: #{$font-size-lg};
    margin: 0 $spacing-100;

    @media($large) {
        margin: $spacing-150 $spacing-100;
    }
}
</style>
