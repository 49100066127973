import { FAILURE_REASON } from '@keap-web/content-sharing-lib';

export const getFailureReasons = (message) => {
    const failureReasons = [];

    Object.values(FAILURE_REASON).forEach((reason) => {
        if (message.includes(reason)) failureReasons.push(reason);
    });

    return failureReasons;
};
