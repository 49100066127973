import { defineStore } from 'pinia';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const flattenTutorialsForUser = (tutorials, userId) => {
    return Object.keys(tutorials).reduce((acc, key) => {
        if (typeof tutorials[key] === 'object') {
            if (tutorials[key][userId] !== undefined) {
                acc[key] = tutorials[key][userId];
            }
        }

        return acc;
    }, {});
};

export const useTutorialStore = defineStore('tutorials', {
    state: () => ({
        items: {},
    }),

    actions: {
        async load(appId, userId) {
            const snapshot = await firebase.database().ref(`tutorials/${appId}`).once('value');
            const items = snapshot.val() || {};
            const tutorials = flattenTutorialsForUser(items, userId);

            this.items = tutorials;
        },

        saveItem(appId, userId, tutorial) {
            const [k] = Object.keys(tutorial);
            const [v] = Object.values(tutorial);

            this.items[k] = v;

            return firebase.database().ref(`tutorials/${appId}/${k}/${userId}`).set(v);
        },

        reset() {
            this.$reset();
        },
    },
});


