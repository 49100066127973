import axios from 'axios';

const TERMS_TYPE = {
    PACKAGING: 'PackagingTerms',
};

export const loadTermsAccepted = async (tenantId) => {
    const queryParams = new URLSearchParams({ tenantId, termsType: TERMS_TYPE.PACKAGING });
    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/consent:check?${queryParams.toString()}`);

    return Boolean(data?.createTime);
};

export const acceptCurrentTerms = async (tenantId) => {
    const { data } = await axios.post(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/consent`, {
        tenantId,
        createdById: 'current',
        terms: {
            termsType: TERMS_TYPE.PACKAGING,
            version: 1,
        },
    });

    return data;
};
