import { createRouter as createVueRouter, createWebHistory } from 'vue-router';

import routes from './routes';
import { beforeResolveRoute } from './utils/router.util';

export const createRouter = () => {
    const router = createVueRouter({
        history: createWebHistory(),
        routes,
    });

    router.beforeResolve((to, from, next) => {
        return beforeResolveRoute(to, from, next);
    });

    return router;
};
