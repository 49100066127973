<template>
    <div class="empty-state">
        <DsIllustration
            v-if="image"
            class="image"
            data-qa="image"
            :name="image"
        />

        <slot name="title">
            <h3 v-if="titleText" data-qa="title">
                {{ titleText }}
            </h3>
        </slot>

        <slot name="description">
            <p v-if="description" data-qa="description">
                {{ description }}
            </p>
        </slot>

        <div v-if="$slots.default" class="slot">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titleText: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.image {
    margin-bottom: $spacing-300;
}

h3 {
    font-family: $font-family-empty-state-title;
    font-weight: $font-weight-bold;
    max-width: $form-width;
}

p {
    color: $color-gray-800;
    margin: $spacing-200 0 0;
    max-width: $form-width;
}

.slot {
    margin-top: $spacing-300;
}
</style>
