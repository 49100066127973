// These values are from v3 account-services backend
export const ACTIVE_UI = {
    WEB: 'KEAP',
    CLASSIC: 'KEAP_CLASSIC',
    ULTIMATE: 'KEAP_ULTIMATE',
};

export const URL_REPLACE_STRING = {
    WEB_BASE: '{baseUrl}',
    CLASSIC_BASE: '{classicBaseUrl}',
    ULTIMATE_BASE: '{ultimateBaseUrl}',
    TENANT_ID: '{tenantId}',
    RESOURCE_ID: '{resourceId}',
};

const {
    WEB_BASE, CLASSIC_BASE, ULTIMATE_BASE, TENANT_ID,
} = URL_REPLACE_STRING;
const { WEB, CLASSIC, ULTIMATE } = ACTIVE_UI;

const createPathObj = (activeUI = WEB, path = '') => {
    switch (activeUI) {
    case CLASSIC:
        return { url: `${CLASSIC_BASE}${path}` };
    case ULTIMATE:
        return { url: `${ULTIMATE_BASE}${path}${path.includes('?') ? '&' : '?'}app_id=${TENANT_ID}`, routePath: path };
    case WEB:
    default:
        return { url: `${WEB_BASE}${path}${path.includes('?') ? '&' : '?'}app_id=${TENANT_ID}`, routePath: path };
    }
};

export const createLinks = (idActiveUi = WEB, idPath = '', fallbackActiveUI = WEB, fallbackPath = '') => {
    return {
        path: createPathObj(idActiveUi, idPath),
        fallback: createPathObj(fallbackActiveUI, fallbackPath),
    };
};
