const ClientsReportPage = () => import('./pages/ClientsReportPage.vue');

const routes = [
    {
        path: '/reports/installs',
        name: 'reports.installs',
        component: ClientsReportPage,
        meta: {
            title: 'app.title',
        },
    },
];

export default routes;
