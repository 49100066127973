import { inject } from 'vue';
import * as Sentry from '@sentry/vue';
import { fullStoryIntegration } from '@sentry/fullstory';
import { FullStory } from '@fullstory/browser';

export const SENTRY_KEY = 'sentry';

export const SEVERITY = {
    FATAL: 'fatal',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    DEBUG: 'debug',
};

const noop = () => {};

function setUser({ casId }) {
    try {
        if (casId) {
            Sentry.getCurrentScope().setUser({
                id: casId,
            });
        }
    } catch (e) {
        // swallow sentry failures
    }
}

function log(message, data = {}, level = SEVERITY.WARNING) {
    try {
        Sentry.withScope((scope) => {
            scope.setLevel(level);

            Object.keys(data).forEach((key) => {
                scope.setExtra(key, data[key]);
            });

            Sentry.captureMessage(message, { level });
        });
    } catch (e) {
        // swallow sentry failures
    }
}

function captureException(error, captureOptions = {}) {
    try {
        Sentry.withScope((scope) => {
            const { message, data } = captureOptions;

            if (message) {
                scope.setTransactionName(message);
            }

            if (data) {
                Object.keys(data).forEach((key) => {
                    scope.setExtra(key, data[key]);
                });
            }

            Sentry.captureException(error);
        });
    } catch (e) {
        // swallow sentry failures
    }
}

export const createSentry = (options) => {
    const {
        enabled,
        router,
        org,
        ignoreErrors = [],
        integrations = [],
        denyUrls = [],
        ...restOptions
    } = options;

    const sentry = {
        install(app) {
            if (enabled) {
                Sentry.init({
                    app,
                    tracesSampleRate: 0.05,
                    ignoreErrors: [
                        /^Request failed with status code \d{3}$/,
                        /Object Not Found Matching Id:\d+/,
                        'Network error: Failed to fetch',
                        '$t is not a function',
                        'ChunkLoadError',
                        'JWT is expired',
                        'JWT is not present in the auth session',
                        'Redirected when going from', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L13
                        'Avoided redundant navigation', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L24
                        'Navigation cancelled', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L33
                        'Navigation aborted', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L44
                        ...ignoreErrors,
                    ],
                    integrations: [
                        fullStoryIntegration(org, { client: FullStory }),
                        Sentry.browserTracingIntegration({ router }),
                        ...integrations,
                    ],
                    denyUrls: [
                        /cdn\.pendo\.io/i,
                        /rs\.fullstory\.com/i,
                        ...denyUrls,
                    ],
                    ...restOptions,
                });
            }

            app.provide(SENTRY_KEY, sentry);
        },
        setUser: enabled ? setUser : noop,
        log: enabled ? log : noop,
        captureException: enabled ? captureException : noop,
        severity: SEVERITY,
    };

    return sentry;
};

export const useSentry = () => {
    return inject(SENTRY_KEY);
};
