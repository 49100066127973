const PageNotFound = () => import('@/pages/PageNotFound.vue');
const LogoutPage = () => import('@/pages/LogoutPage.vue');
const KillSessionPage = () => import('@/pages/KillSessionPage.vue');

import shareRoutes from '@/share/share.routes';
import reportingRoutes from '@/reporting/reporting.routes';

const routes = [...shareRoutes, ...reportingRoutes];

routes.push(
    {
        path: '/',
        name: 'home',
        redirect: '/shared',
    },
    {
        path: '/page-not-found',
        name: 'page.not.found',
        component: PageNotFound,
        meta: {
            title: 'notFound.pageTitle',
            isPublic: true,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutPage,
        meta: {
            title: 'logout.pageTitle',
            isPublic: true,
        },
    },
    {
        path: '/killSession',
        name: 'kill.session',
        component: KillSessionPage,
        meta: {
            title: 'logout.pageTitle',
            isPublic: true,
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/page-not-found',
    },
);

export default routes;
