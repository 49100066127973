import axios from 'axios';
import * as FEATURE_FLAGS from '@/constants/featureFlag.constants';

const featureFlagNames = Object.keys(FEATURE_FLAGS)
    .map((key) => FEATURE_FLAGS[key])
    .filter((key) => typeof key === 'string');

export const loadFeatureFlags = async (accountId) => {
    const featureFlagsMap = {};

    if (featureFlagNames && featureFlagNames.length > 0) {
        const { data } = await axios.post(`${window.__KEAP__.VUE_APP_FEATURE_FLAG_SERVICE_URL}/api/v4/featureFlag`, {
            accountId,
            featureFlagNames,
            platform: 'web',
            version: '0.0.0', // doesn't matter unless we create flags to target a specific version
        });
        const { featureFlags = {} } = data;

        for (const flag of featureFlagNames) {
            featureFlagsMap[flag] = Boolean(featureFlags[flag] && featureFlags[flag].enabled);
        }
    }

    return featureFlagsMap;
};
