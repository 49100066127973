<template>
    <button
        class="video-placeholder"
        :class="[{ fill }]"
        :style="{ 'background-image': `url(${placeholderImage})` }"
        @click="$emit('click')"
    >
        <div class="play-button" :class="[{ small }]">
            <DsIcon name="play-fill" />
        </div>
    </button>
</template>

<script>
import { DsIcon } from '@infusionsoft/design-system';

export default {
    components: {
        DsIcon,
    },

    props: {
        placeholderImage: {
            type: String,
            required: true,
        },
        small: Boolean,
        fill: Boolean,
    },

    emits: ['click'],
};
</script>

<style lang="scss" scoped>
    @import '../../styles/common';

    .video-placeholder {
        display: block;
        width: 100%;
        position: relative;
        padding-bottom: 52.25%; /* 16:9 */
        padding-top: px-to-rem(25px);
        height: 0;
        background-color: $color-gray-200;
        background-size: cover;
        background-position: center;
        border: none;
        border-radius: $border-radius;

        &.fill {
            padding-top: 0;
            padding-bottom: 0;
            height: 100%;
        }
    }

    .play-button {
        @include transition(all);

        --icon-size: #{px-to-rem(40px)};
        --icon-color: #{$color-paper};

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: px-to-rem(80px);
        height: px-to-rem(80px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color-blue, 0.5);
        border-radius: 50%;

        .video-placeholder:hover & {
            transform: translate(-50%, -50%) scale(1.1);
        }

        .video-placeholder:active & {
            transform: translate(-50%, -50%) scale(1.05);
        }

        &.small {
            width: px-to-rem(40px);
            height: px-to-rem(40px);

            --icon-size: #{px-to-rem(20px)};
        }
    }
</style>
