import axios from 'axios';
import { LINK_TYPE, LICENSE_TYPE } from '@keap-web/content-sharing-lib';

export const loadLinkFromList = async ({
    tenantId, licenseType, sharedResourceId, partnerGroupId,
}) => {
    const params = {
        tenantId,
        sharedResourceId,
        pageSize: 10,
        orderBy: ['status:ASC', 'createTime:DESC'],
    };

    if (licenseType) {
        params.licenseTypes = [licenseType];
    }

    const queryParams = new URLSearchParams(params);
    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/links?${queryParams}`);
    let link = data?.links?.length ? data.links[0] : {};

    // TODO update to use the partnerGroupId when the API supports it
    if (licenseType === LICENSE_TYPE.BUY_NOW && data?.links?.length) {
        if (partnerGroupId) {
            link = data.links.find((l) => partnerGroupId === l.partnerGroupId);
        } else {
            link = data.links.find((l) => !l.partnerGroupId);
        }
    }

    return link || {};
};

const linkInfoFromData = (data) => {
    return {
        title: data.sharedResource.title,
        description: data.sharedResource.description,
        resourceType: data.sharedResource.resourceType,
        topLevel: data.sharedResource.topLevel,
        linkType: data.status,
        deleted: data.sharedResource.deleted,
        company: data.company,
    };
};

export const getPublicLinkInfo = async (linkId) => {
    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/preview/links/${linkId}`);

    return linkInfoFromData(data);
};

export const getPublicLinkDependencies = async (linkId) => {
    const queryParams = new URLSearchParams({
        pageSize: 1000, // we just need the first page while firstLevelOnly is true (the default)
    });
    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/preview/links/${linkId}/dependencies?${queryParams}`);

    return {
        dependencies: data.dependencies,
        linkId,
    };
};

export const unpublishLink = async (link) => {
    await axios.patch(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/links/${link.linkId}?updateMask=status`, {
        status: LINK_TYPE.UNPUBLISHED,
        updatedById: 'current',
    });
};

export const republishLink = async (link) => {
    await axios.patch(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/links/${link.linkId}?updateMask=status`, {
        status: LINK_TYPE.PUBLISHED,
        updatedById: 'current',
    });
};

export const createLink = async ({
    sharedResourceId, licenseType, installLimit, partnerGroupId,
}) => {
    const payload = {
        sharedResource: {
            sharedResourceId,
        },
        status: LINK_TYPE.PUBLISHED,
        createdBy: 'current',
        licenseType,
        installLimit,
    };

    if (partnerGroupId) {
        payload.partnerGroupId = partnerGroupId;
    }

    const { data } = await axios.post(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/links`, payload);

    return data;
};
